import { deserializeListData } from '@apeiron/temp'
import { useLazyQuery } from '@apollo/client'
import { BannerType } from '@src/constants/banner'
import { bannerSchema } from '@src/deserialize/yup/banner'
import graphql from '@src/graphql/query/getBanners'
import { Banner } from '@src/types/banner'
import { useCallback, useState } from 'react'

const useGetBanners = (): Hook => {
  const [query, { loading }] = useLazyQuery(graphql)

  const [data, setData] = useState<Banner[]>([])

  const fetch = useCallback(
    async (request: Request): Promise<Response> => {
      const { bannerTypes = [] } = request

      const response = await query({
        variables: {
          input: {
            bannerTypes
          }
        }
      })

      const banners = deserializeListData<Banner>(
        response,
        bannerSchema,
        null,
        ['banners']
      ) as Banner[]

      setData(banners)

      return {
        data: banners
      }
    },
    [query]
  )

  return {
    data,
    fetch,
    loading
  }
}

type Request = {
  bannerTypes: BannerType[]
}

type Response = {
  data: Banner[]
}

type Hook = {
  data: Banner[]
  fetch: (request: Request) => Promise<Response>
  loading: boolean
}

export default useGetBanners
