import {
  ApeironLoopVideo,
  DownloadButtons,
  DownloadInfo,
  DownloadLogo,
  hyphenLocale,
  NextImage,
  NextLink
} from '@apeiron/temp'
import { Box, styled, useMediaQuery } from '@mui/material'
import BattleDemoTitle from '@src/components/battle/demo/share/BattleDemoTitle'
import { FeatureFlagType } from '@src/constants/featureFlag'
import { GAEventCategory } from '@src/constants/googleAnalytics'
import { DEFAULT_LOCALE } from '@src/constants/locale'
import { ROUTE } from '@src/constants/route'
import useFetchCurrentSeasonJson from '@src/hooks/json/useFetchCurrentSeasonJson'
import theme from '@src/styles/theme'
import featureAllowed from '@src/util/apeiron/featureAllowed'
import * as R from 'ramda'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

const Container = styled(Box)`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 1080px;
`

const BackgroundImage = styled(NextImage)`
  aspect-ratio: 1080 / 740;
  min-height: 100%;
  position: absolute;
`

const BackgroundVideo = styled(ApeironLoopVideo)`
  filter: contrast(1.05) saturate(1.1);
  height: 1080px;
  object-fit: cover;
  position: absolute;
  width: 100%;
`

const StyledDownloadLogo = styled(DownloadLogo)`
  margin-top: 130px;
`

const Title = styled(BattleDemoTitle)`
  align-self: flex-start;
  position: relative;
  ${props => props.theme.breakpoints.up('res1024')} {
    margin: 45px 0px 0px 90px;
  }
  ${props => props.theme.breakpoints.down('res1024')} {
    margin: 40px 0px 0px 20px;
  }
`

const Placeholder = styled(Box)`
  height: 64px;
  ${props => props.theme.breakpoints.up('res1024')} {
    margin: 45px 0px 0px 90px;
  }
  ${props => props.theme.breakpoints.down('res1024')} {
    margin: 40px 0px 0px 20px;
  }
`

const TextLink = styled(NextLink)`
  font-size: 16px;
  font-weight: 300;
  margin-top: 15px;
  text-decoration: underline;
  position: relative;
  ${props => props.theme.breakpoints.up('res1024')} {
    margin-left: 500px;
  }
`

const DownloadSection: FC<Props> = props => {
  const { infoList, showTitle } = props

  const isMobile = useMediaQuery(theme.breakpoints.down('res768'))

  const { t } = useTranslation()

  const { data } = useFetchCurrentSeasonJson()

  const breakpoint = isMobile
    ? data?.breakpoint.mobile
    : data?.breakpoint.desktop

  return R.isNotNil(data) && R.isNotNil(breakpoint) ? (
    <Container>
      {isMobile ? (
        <BackgroundImage src={breakpoint.background} />
      ) : (
        <BackgroundVideo src={breakpoint.background} playbackRate={1.5} />
      )}
      {showTitle ? (
        <Title
          apeironTitle={t('battle_demo.download.page_title', {
            lng: hyphenLocale(DEFAULT_LOCALE)
          })}
          title={t('battle_demo.download.page_title')}
        />
      ) : (
        <Placeholder />
      )}
      <StyledDownloadLogo
        barColor={breakpoint.barColor}
        barSide={breakpoint.barSide}
        logo={breakpoint.logo}
        name={data.name}
        title={data.title}
      />
      <DownloadButtons
        analytic={{ emitById: true, event: GAEventCategory.Download }}
        infoBackground={breakpoint.barVersion}
        infoList={infoList}
      />
      {featureAllowed(FeatureFlagType.ShowBattleDemoReleaseNote) && (
        <TextLink href={ROUTE.ACCOUNT_SETTING}>
          {`${t('battle_demo.release_note')} >>`}
        </TextLink>
      )}
      <TextLink
        href={
          'https://docs.google.com/document/d/1y61JZfBlupyxC7aUurVOAf0-n-lwStfTmkyIf-aMTT0/edit?usp=sharing'
        }
        target='_blank'
      >
        {`${t('battle_demo.faq')} >>`}
      </TextLink>
    </Container>
  ) : (
    <></>
  )
}

type Props = {
  infoList: DownloadInfo[]
  showTitle: boolean
}

export default DownloadSection
