import { NextImage } from '@apeiron/temp'
import { Box, styled } from '@mui/material'
import React, { FC, ReactNode } from 'react'

const Container = styled(Box)`
  display: flex;
  gap: 0px;
`

const Left = styled(NextImage)`
  width: 34px;
`

const Right = styled(Left)`
  transform: scaleX(-1);
`

const Center = styled(Box)`
  flex: 1;
`

const ApeironImageSideBox: FC<Props> = props => {
  const { children, className, leftImage } = props

  return (
    <Container className={className}>
      <Left className='Custom-Left' src={leftImage} />
      <Center className='Custom-Center'>{children}</Center>
      <Right className='Custom-Right' src={leftImage} />
    </Container>
  )
}

type Props = {
  className?: string
  children?: ReactNode
  leftImage: string
}

export default ApeironImageSideBox
