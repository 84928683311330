import { FetchJsonProps, useReactQueryFetchJson } from '@apeiron/temp'
import ENV_CONFIG from '@src/config'
import useMakeSeason from '@src/deserialize/hook/useMakeSeason'
import { CurrentSeason } from '@src/types/season'

const useFetchCurrentSeasonJson = (props?: FetchJsonProps) => {
  const { fetchNow } = props || {}

  const { makeCurrentSeason } = useMakeSeason()

  return useReactQueryFetchJson<CurrentSeason>({
    fetchNow,
    path: ENV_CONFIG.JSON_URL.CURRENT_SEASON,
    deserialize: makeCurrentSeason
  })
}

export default useFetchCurrentSeasonJson
