import { FetchJsonProps, useReactQueryFetchJson } from '@apeiron/temp'
import ENV_CONFIG from '@src/config'
import { BattleDemoAbout } from '@src/types/battleDemo'

import { battleDemoAboutSchema } from '../../deserialize/yup/battleDemo'

const useFetchBattleDemoAboutJson = (props?: FetchJsonProps) => {
  const { fetchNow } = props || {}

  return useReactQueryFetchJson<BattleDemoAbout>({
    fetchNow,
    path: ENV_CONFIG.JSON_FILE_NAME.BATTLE_DEMO_ABOUT,
    deserialize: (response: any) =>
      battleDemoAboutSchema.cast(response) as BattleDemoAbout
  })
}

export default useFetchBattleDemoAboutJson
