import {
  ApeironLinkButton,
  DEFAULT_ANIMATE_DURATION,
  NextImage
} from '@apeiron/temp'
import { Box, styled, Typography } from '@mui/material'
import LinkLeftPNG from '@public/images/image-battle-demo-shortcut-background.png'
import GuideJPG from '@public/images/image-battle-demo-shortcut-guide.jpg'
import LeaderboardJPG from '@public/images/image-battle-demo-shortcut-leaderboard.jpg'
import StoryJPG from '@public/images/image-battle-demo-shortcut-story.jpg'
import TokenJPG from '@public/images/image-battle-demo-shortcut-token.jpg'
import TitleLinePNG from '@public/images/image-section-header-block.png'
import ApeironImageSideBox from '@src/components/share/apeiron/ApeironImageSideBox'
import ENV_CONFIG from '@src/config'
import { ROUTE } from '@src/constants/route'
import * as R from 'ramda'
import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

const Container = styled(Box)`
  align-items: center;
  align-self: center;
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: flex-start;
  max-width: 1024px;
  width: 100%;
`

const Button = styled(ApeironLinkButton)`
  align-items: center;
  aspect-ratio: 1200 / 285;
  display: flex;
  justify-content: center;
  width: 100%;
  position: relative;
  transition: filter ${DEFAULT_ANIMATE_DURATION}ms;
  :hover {
    filter: brightness(1.1);
  }
`

const Image = styled(NextImage)`
  position: absolute;
  height: 100%;
  width: 100%;
`

const Title = styled(ApeironImageSideBox)`
  gap: 10px;

  .Custom-Left {
    width: 200px;
  }
  .Custom-Right {
    width: 200px;
  }
  .Custom-Center {
    align-items: center;
    display: flex;
    font-size: 28px;
    position: relative;
    text-shadow: 0px 4px 6px #000000;
  }
`

const Link = styled(ApeironImageSideBox)`
  bottom: 0;
  position: absolute;
  .Custom-Center {
    background-color: #40a6ff;
    padding: 5px 20px;
  }
`

const LinkText = styled(Typography)`
  white-space: nowrap;
  text-decoration: underline;
`

const BottomLine = styled(Box)`
  background-color: #40a6ff77;
  height: 3px;
  bottom: 0;
  position: absolute;
  width: 100%;
`

const ShortcutItem: FC<ShortcutPtops> = props => {
  const { shortcut } = props

  return (
    <Button url={shortcut.link} target={shortcut.newTab ? '__blank' : '_self'}>
      <Image src={shortcut.image} />
      <Title leftImage={TitleLinePNG.src}>{shortcut.title}</Title>
      <BottomLine />
      <Link leftImage={LinkLeftPNG.src}>
        <LinkText>{shortcut.linkText}</LinkText>
      </Link>
    </Button>
  )
}

const ShortcutItems: FC<Props> = () => {
  const { t } = useTranslation()

  const shortcuts = useMemo((): Shortcut[] => {
    return [
      {
        id: 'guide',
        image: GuideJPG.src,
        link: ROUTE.BATTLE_DEMO_BEGINNERS_GUIDE,
        linkText: t('battle_demo.shortcut_link.guide'),
        newTab: false,
        title: t('battle_demo.shortcut.guide')
      },
      {
        id: 'token',
        image: TokenJPG.src,
        link: ROUTE.BATTLE_DEMO_ECONOMY,
        linkText: t('battle_demo.shortcut_link.economy'),
        newTab: false,
        title: t('battle_demo.shortcut.economy')
      },
      {
        id: 'leaderboard',
        image: LeaderboardJPG.src,
        link: ROUTE.BATTLE_DEMO_SOLO_PVE,
        linkText: t('battle_demo.shortcut_link.leaderboard'),
        newTab: false,
        title: t('battle_demo.shortcut.leaderboard')
      },
      {
        id: 'story',
        image: StoryJPG.src,
        link: ENV_CONFIG.EXTERNAL_URL.BRANDING.CHARACTER,
        linkText: t('battle_demo.shortcut_link.character'),
        newTab: true,
        title: t('battle_demo.shortcut.character')
      }
    ]
  }, [t])

  return (
    <Container>
      {R.map((shortcut: Shortcut) => {
        return <ShortcutItem key={shortcut.id} shortcut={shortcut} />
      }, shortcuts)}
    </Container>
  )
}

type Shortcut = {
  id: string
  image: string
  link: string
  linkText: string
  newTab: boolean
  title: string
}

type ShortcutPtops = {
  shortcut: Shortcut
}

type Props = {}

export default ShortcutItems
